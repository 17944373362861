﻿<script>
	import { onMount } from 'svelte';
	import { GetSiteSettings } from '../shared/utils';
	import { SiteSettings } from '../shared/store';
    import Swal from 'sweetalert2';
	import Fa from 'svelte-fa';
	import { fa1, fa3, fa5, faArrowsUpDown, faBaseball, faFootball, faCircleDot } from '@fortawesome/free-solid-svg-icons';
	import { Spinner } from 'sveltestrap';
	import { user, isLoggedIn } from '../shared/store';
	import { get } from 'svelte/store';
	
	import PoolLeague from './PoolLeague.svelte';
	import ScoreAdmin from './ScoreAdmin.svelte';
	
	let showAdminButtons = false
	let showPoolLeague = false
	let showScoreAdmin = false

	import MLBStandings from './MLBStandings.svelte';
	import MLB13Run from './MLB13Run.svelte';

	import NFLStandings from './NFLStandings.svelte';
	import NFL33Point from './NFL33Point.svelte';

	 //covers both MLB and NFL
	import Pick5 from './Pick5.svelte';
	import OverUnder from './OverUnder.svelte';

	let MLBShowCard = "Standings";
	let NFLShowCard = "Standings";
	let showSpinner = false;

	$:($user)

	$: checkLoggedIn($isLoggedIn)

	function checkLoggedIn() {
		showAdminButtons = get(isLoggedIn) == true && $user.isAdmin
	}

	onMount(async () => {
		let settings = await GetSiteSettings()
		SiteSettings.set(settings.data);
		checkLoggedIn()
	});

	function pick5MLB_Click() {}

	function showMLBCard(card) {
		MLBShowCard = card;
	}

	function showNFLCard(card) {
		NFLShowCard = card;
	}
</script>

{#if !showPoolLeague && !showScoreAdmin}
	{#if showAdminButtons == true}
		<div class="col-12 eug-inline eug-valign-top">
			<div class="eug-inline">
				{#if ($user.isAdmin) || ($user.isPool) }
					<button class="btn btn-primary eug-btn" on:click={() => { showPoolLeague = true }} >
						<span class="eug-btn-icon-wrapper">
							<Fa class="eug-btn-icon" icon={faCircleDot}/>
						</span>	
						<span>Pool</span>
						{#if showSpinner}
							<Spinner class="ms-2" size="sm" color='light' />
						{/if}		
					</button>
					<button class="btn btn-primary eug-btn" on:click={() => { showScoreAdmin = true }} >
						<span class="eug-btn-icon-wrapper">
							<Fa class="eug-btn-icon" icon={faCircleDot}/>
						</span>	
						<span>Scores</span>
						{#if showSpinner}
							<Spinner class="ms-2" size="sm" color='light' />
						{/if}		
					</button>
				{/if}		
			</div>
		</div>
	{/if}
	<div class="col-12">
		<div class="stand-col eug-inline eug-valign-top" style="text-align: center">
			<div class="eug-inline eug-valign-top" style="margin-bottom: 6px;">
				<div class="leagueHeader">MLB</div>
				<button class="btn btn-primary eug-btn" on:click={() => showMLBCard("Standings") } >
					<span class="eug-btn-icon-wrapper">
						<Fa class="eug-btn-icon" icon={faBaseball}/>
					</span>	
					<span>Standings</span>
					{#if showSpinner}
						<Spinner class="ms-2" size="sm" color='light' />
					{/if}		
				</button>
				{#if $isLoggedIn}
					<button class="btn btn-primary eug-btn" on:click={() => showMLBCard("Pick5") }>
						<span class="eug-btn-icon-wrapper">
							<Fa class="eug-btn-icon-1" icon={fa5}/>
						</span>
						<span>Pick 5</span>
						{#if showSpinner}
							<Spinner class="ms-2" size="sm" color='light' />
						{/if}		
					</button>
					<button class="btn btn-primary eug-btn" on:click={() => showMLBCard("13Run") }>
						<span class="eug-btn-icon-wrapper">
							<Fa class="eug-btn-icon-1" icon={fa1}/>
							<Fa class="eug-btn-icon-2" icon={fa3}/>
						</span>
						<span>13 Run</span>
						{#if showSpinner}
							<Spinner class="ms-2" size="sm" color='light' />
						{/if}		
					</button>
					<button class="btn btn-primary eug-btn" on:click={() => showMLBCard("OverUnder")}>
						<span class="eug-btn-icon-wrapper">	
							<Fa icon={faArrowsUpDown}/>
						</span>
						<span>Over/Under</span>
						{#if showSpinner}
							<Spinner class="ms-2" size="sm" color='light' />
						{/if}		
					</button>
				{/if}
			</div>
			<div class="flex">
				{#if MLBShowCard == "Standings"}
					<MLBStandings />
				{/if}
				{#if $isLoggedIn}
					{#if MLBShowCard == "Pick5"}
						<Pick5 league="MLB" />
					{/if}	
					{#if MLBShowCard == "13Run"}
						<MLB13Run />
					{/if}	
					{#if MLBShowCard == "OverUnder"}
						<OverUnder league="MLB" />
					{/if}	
				{/if}	
			</div>
		</div>
		<div class="stand-col eug-inline" style="text-align: center">
			<div class="eug-inline eug-valign-top" style="margin-bottom: 6px;">
				<div class="leagueHeader">NFL</div>
				<button class="btn btn-primary eug-btn" on:click={() => showNFLCard("Standings") } >
					<span class="eug-btn-icon-wrapper">
						<Fa class="eug-btn-icon" icon={faFootball}/>
					</span>	
					<span>Standings</span>
					{#if showSpinner}
						<Spinner class="ms-2" size="sm" color='light' />
					{/if}		
				</button>
				{#if $isLoggedIn}
					<button class="btn btn-primary eug-btn" on:click={ () => showNFLCard("Pick5") }>
						<span class="eug-btn-icon-wrapper">
							<Fa class="eug-btn-icon-1" icon={fa5}/>
						</span>
						<span>Pick 5</span>
						{#if showSpinner}
							<Spinner class="ms-2" size="sm" color='light' />
						{/if}		
					</button>
					<button class="btn btn-primary eug-btn" on:click={ () => showNFLCard("33Point") }>
						<span class="eug-btn-icon-wrapper">
							<Fa class="eug-btn-icon-1" icon={fa3}/>
							<Fa class="eug-btn-icon-2" icon={fa3}/>
						</span>
						<span>33 Point</span>
						{#if showSpinner}
							<Spinner class="ms-2" size="sm" color='light' />
						{/if}		
					</button>
					<button class="btn btn-primary eug-btn" on:click={ () => showNFLCard("OverUnder") }>
						<span class="eug-btn-icon-wrapper">	
							<Fa icon={faArrowsUpDown}/>
						</span>
						<span>Over/Under</span>
						{#if showSpinner}
							<Spinner class="ms-2" size="sm" color='light' />
						{/if}		
					</button>
				{/if}
			</div>
			<div class="flex">
				{#if NFLShowCard == "Standings"}
					<NFLStandings />
				{/if}	
				{#if $isLoggedIn}
					{#if NFLShowCard == "Pick5"}
						<Pick5 league="NFL" />
					{/if}	
					{#if NFLShowCard == "33Point"}
						<NFL33Point league="NFL"/>
					{/if}	
					{#if NFLShowCard == "OverUnder"}
						<OverUnder league="NFL" />
					{/if}	
				{/if}
			</div>
		</div>
	</div>
{/if}
{#if showPoolLeague}
	<div class="pool-div">
		<PoolLeague bind:showLeague={ showPoolLeague } />
	</div>
{/if}
{#if showScoreAdmin} 
	<div class="pool-div">
		<ScoreAdmin bind:showScore={ showScoreAdmin } />
	</div>
{/if}

<style>
	.stand-col {
		width: 49%;
	}

	 .pool-div {
		 padding-top: 30px;
	 }

	 .leagueHeader {
		 margin-bottom: 6px;
	 }
</style>