<script>
	import { onMount } from 'svelte';	
	import { GetPoolLeagueData, SaveMatchData, SaveSwitchPlayer } from '../shared/pool-data';
	import Fa from 'svelte-fa';
	import { faArrowDown, faArrowRight, faSquare, faShuffle, faXmark, faSquareCheck, faSave } from '@fortawesome/free-solid-svg-icons';
    import Modal from './shared/Modal.svelte';
    import EugDropdown from './shared/DropDown.svelte';
    import { CreateKVP } from '../shared/utils';

	export let showLeague = false;

	let teams = [];
	let players = [];
	let poolLeagueData = {};
	let weekData = {};
	
	let poolWeeks = [];
	let selectedWeek = 1;

	let weekMatches = [];
	let selectedMatch = 1;

	let curAwayWinIcon = faSquare;
	let curHomeWinIcon = faSquare;

	let curAwayHDCPIcon = faSquare;
	let curHomeHDCPIcon = faSquare;

	let showHomeHDCP = false;
	let showAwayHDCP = false;

	let showPlayersModal = false;
	let switchPlayerList = [];
	let swithIsHome = false;
	let replacePlayerID = "";
	let switchSelPlayer = "";
	
	$: { poolWeeks };

	onMount(async () => {
		var result = await GetPoolLeagueData();

		if (result)	{
			poolLeagueData = result.data;

			GetLeagueData()
		}
	});

	let GetLeagueData = function() {

		teams = [];
		poolLeagueData.Teams.forEach(function(team) {
			teams.push({
				Team: team.Team,
				Points: 0
			})
		})

		players = [];
		poolLeagueData.Players.forEach(function(player) {
			players.push({
				PlayerID: player.PlayerID,
				Player: player.Name,
				Games: 0,
				Balls: 0,
				HDCP: 0
			})
		})

		poolWeeks = poolLeagueData.Games.map(a => a.Week);

		var done = false;
		poolLeagueData.Games.forEach(function(game) {
			game.Matches.forEach(function(match) {
				if (game.Week < selectedWeek) {
					if (match.Away.Points > 0)
						AddPoints(match.Away.Team, match.Away.Points)
					else
						AddPoints(match.Home.Team, match.Home.Points)

					AddPlayerData(match.Away);
					AddPlayerData(match.Home);
				}
			});
		})

		teams = teams.sort((a,b)=> b.Points - a.Points);

		SetWinIcon(false);
	}

	let AddPoints = function(teamName, points) {
		var team = teams.find(x => x.Team == teamName);
		team.Points = team.Points + points;
	}

	let AddPlayerData = function(matchData) {
		var player = players.find(x => x.PlayerID == matchData.PlayerID);
		player.Games += 1;
		player.Balls += matchData.Balls;
		if (selectedWeek > 1)
			player.HDCP = player.Balls / player.Games
	}

	let showWeek = function(wk) {
		weekData = poolLeagueData.Games.find(function(game) {
			return game.Week == wk;
		});
		weekMatches = weekData.Matches;
		selectedWeek = wk;
		selectedMatch = 1;

		GetLeagueData();

		setSelection();
	}

	let getPlayerName = function(playerID) {
		return players.find(x => x.PlayerID == playerID).Player;
	}

	let getPlayerHDCP = function(playerID) {
		return players.find(x => x.PlayerID == playerID).HDCP;
	}

	async function showSwitchPlayer(isHome) {
		var match = poolLeagueData.Games[selectedWeek - 1].Matches[selectedMatch - 1];
		if (isHome)
			replacePlayerID = match.Home.PlayerID;
		else
			replacePlayerID = match.Away.PlayerID;

		if (switchPlayerList.length == 0) {
			switchPlayerList = await CreateKVP(players, "Player", "Player");
		}
	
		swithIsHome = isHome;
		showPlayersModal = true;
	}

	async function SwitchPlayer() {
		var switchData = {};
		switchData.Week = selectedWeek;
		switchData.ReplacePlayerID = replacePlayerID;
		switchData.SwitchPlayer = switchSelPlayer;

		var rtnResult = await SaveSwitchPlayer(switchData)

		if (rtnResult) {
			poolLeagueData = rtnResult.data;
			await GetLeagueData();

			showWeek(selectedWeek);
			showPlayersModal = false;
		}		
	}

	let changeWin = function(isHome) {
		var match = poolLeagueData.Games[selectedWeek - 1].Matches[selectedMatch - 1];

		if (isHome) {
			match.Home.Win = match.Home.Win == 1 ? 0 : 1;
			match.Away.Win = match.Home.Win == 1 ? 0 : 1;
		}
		else {
			match.Away.Win = match.Away.Win == 1 ? 0 : 1;
			match.Home.Win = match.Away.Win == 1 ? 0 : 1;
		}

		showHomeHDCP = match.Home.Win == 1;
		showAwayHDCP = match.Away.Win == 1;

		SetWinIcon();
	}

	let SetWinIcon = function() {
		var match = poolLeagueData.Games[selectedWeek - 1].Matches[selectedMatch - 1];

		curAwayWinIcon = match.Away.Win == 0 ? faSquare : faSquareCheck;
		curHomeWinIcon = match.Home.Win == 0 ? faSquare : faSquareCheck;
	}

	let checkHDCP = function(isHome, match) {
		if (selectedWeek == 1)
			return;

		var playerHDCP = Math.round(getPlayerHDCP(match.PlayerID));

		match.HDCP = 0;
		if (match.Balls >= playerHDCP)
			match.HDCP = 1;

		if (isHome) 
			curHomeHDCPIcon =  match.HDCP == 1 ? faSquareCheck : faSquare;
		else 
			curAwayHDCPIcon = match.HDCP == 1 ? faSquareCheck : faSquare;
	}

	let setSelection = function() {
		SetWinIcon();

		var match = poolLeagueData.Games[selectedWeek - 1].Matches[selectedMatch - 1];

		curHomeHDCPIcon = match.Home.HDCP == 1 ? faSquareCheck : faSquare;
		curAwayHDCPIcon = match.Away.HDCP == 1 ? faSquareCheck : faSquare;

		showHomeHDCP = match.Home.Win == 1;
		showAwayHDCP = match.Away.Win == 1;
	}

	async function SaveMatch() {
		var match = poolLeagueData.Games[selectedWeek - 1].Matches[selectedMatch - 1];

		var winningTeam = match.Home.Win == 1 ? match.Home : match.Away;

		var saveData = {};
		saveData.Week = selectedWeek;
		saveData.Match = selectedMatch;
		saveData.MatchData = winningTeam;

		var result = await SaveMatchData(saveData);

		if (result)	{

		}
	}
</script>

<div class="col-12 pool-wrapper">
	<div class="col-11 pool-page">
		<div class="pool-x">
			<button class="btn btn-dark" on:click={() => showLeague = !showLeague }>
				<Fa icon={faXmark}/>
			</button>
		</div>
		<div class="col-12 d-flex" style="text-align: left;">
			<div class="col-2">
				<span class="pool-heading">Standings</span>
				{#each teams as tm}
					<div>
						<span class="pool-stn-team">{ tm.Points }</span>
						<span class="pool-stn-team">{ tm.Team }</span>
					</div>
				{/each}
			</div>
			<div class="col-10">
				<span class="pool-heading">Weeks:</span>
				{#each poolWeeks as wk }
					{#if wk == selectedWeek}
						<button class="btn btn-dark btn-selected pool-wk-link" on:click={() => showWeek(wk) }>{ wk }
							<span class="btn-image-after"><Fa icon={faArrowDown}/></span>
						</button>
					{:else}
						<button class="btn btn-dark pool-wk-link" on:click={() => showWeek(wk) }>{ wk }</button>
					{/if}
				{/each}
				<hr/>
				<div class="pool-sub-heading">
					{#each weekMatches as match}
						<div on:click={() => { selectedMatch = match.Match; setSelection() }}>
							<span class="match-hdr">Match { match.Match }:
							{#if match.Match == selectedMatch}
								<span class="btn-image-after"><Fa icon={faArrowRight}/></span>
							{/if}
							</span>
							<span class="match-sched-away">Team: { match.Away.Team }</span><span class="match-sched-split">@ </span><span class="match-sched-home">Team: { match.Home.Team }</span>
							<hr />					
						</div>
						{#if match.Match == selectedMatch}
							<div class="col-12 match-info d-flex">
								<span>
									<button class="btn btn-dark btn-switch" title="Save" on:click={() => SaveMatch() }>
										<span><Fa icon={faSave}/></span>
									</button>
								</span>
								<span class="col-4">
									<div>
										<span class="away-label">Player:</span>
										<span class="away-value">{ getPlayerName(match.Away.PlayerID) }
											<span class="lblHDCP">{ Math.round(getPlayerHDCP(match.Away.PlayerID)) }</span>
											<span class="lblHDCP"> ({ getPlayerHDCP(match.Away.PlayerID).toFixed(2) })</span>
											<button class="btn btn-dark btn-switch" title="switch player" on:click={() => showSwitchPlayer(false) }>
												<span><Fa icon={faShuffle}/></span>
											</button>
										</span>
									</div>
									<div style="margin-bottom: 8px;">
										<span class="result-row-label row-1">Win:</span>
										<span class="result-row-value">
											<span on:click={() => changeWin(false) }>
												<Fa icon={ curAwayWinIcon }/>
											</span>
										</span>
										<span class="result-row-label">Balls:</span>
										<span class="result-row-value">
											<input class="pool-balls-input" type="number" bind:value={match.Away.Balls} on:keyup={ checkHDCP(false, match.Away) } />
										</span>
										<span class="result-row-label">HDCP:</span>
										<span class="result-row-value">
											<Fa icon={ curAwayHDCPIcon }/>
										</span>
									</div>
									<hr />
								</span>
								<span class="col-4">
									<div>
										<span class="away-label">Player:</span>
										<span class="home-label">{ getPlayerName(match.Home.PlayerID) }
											<span class="lblHDCP">{ Math.round(getPlayerHDCP(match.Home.PlayerID)) }</span>
											<span class="lblHDCP"> ({ getPlayerHDCP(match.Home.PlayerID).toFixed(2) })</span>
											<button class="btn btn-dark btn-switch" title="switch player" on:click={() => showSwitchPlayer(true) }>
												<span><Fa icon={faShuffle}/></span>
											</button>
										</span>
									</div>
									<div style="margin-bottom: 8px;">
										<span class="result-row-label row-1">Win:</span>
										<span class="result-row-value">
											<span on:click={() => changeWin(true) }>
												<Fa icon={ curHomeWinIcon }/>
											</span>
										</span>
										<span class="result-row-label">Balls:</span>
										<span class="result-row-value">
											<input class="pool-balls-input" type="number" bind:value={match.Home.Balls} on:keyup={ checkHDCP(true, match.Home) } />
										</span>
										<span class="result-row-label">HDCP:</span>
										<span class="result-row-value">
											<span>
												<Fa icon={ curHomeHDCPIcon }/>
											</span>
										</span>
									</div>
									<hr />
								</span>
							</div>
						{/if}
					{/each}
				</div>
			</div>
		</div>
	</div>
</div>

<Modal bind:isOpen={ showPlayersModal } title="Switch Player">
	<div slot="content">
		<EugDropdown itemList={ switchPlayerList } bind:selItem={ switchSelPlayer } />
	</div>
	<div slot="buttons">
		<button class="me-1 btn btn-secondary" on:click={() => { showPlayersModal = false }}>Cancel</button>
		<button class="me-1 btn btn-secondary" on:click={ () => { SwitchPlayer() } }>Switch</button>
	</div>
</Modal>

<style>
	.pool-wrapper {
		display: flex;
		justify-content: center;
	}

	.pool-page {
		border: 1px solid var(--bs-primary);
		border-radius: 6px;
		background-color: lightgray;
		color: var(--bs-primary);
		padding: 10px;
		font-size: 20px;
	}

	.pool-x {
		position: absolute;
		top: 94px;
		left: 92%
	}

	.pool-heading {
		position: relative;
		font-size: 24px;
		font-weight: 900;
		top: 4px;
	}

	.pool-stn-team {
		margin-left: 30px;
	}

	.pool-balls-input {
		max-width: 40px;
		height: 30px;
		font-size: 14px;
		font-weight: bold;
		background-color: transparent;
		color: var(--bs-primary);
		border-radius: 6px;
		border: 1px solid var(--bs-primary);
		padding-left: 8px;
	}

	.lblHDCP {
		margin-left: 8px;
	}

	.btn-selected {
		background-color: var(--bs-primary);
	}

	.btn-image-after {
		margin-left: 8px;
	}

	.btn-switch {
		margin: 0 20px 0 20px;
	}

	.match-hdr {
		display: inline-flex;
		margin-left: 20px;
		min-width: 120px;
	}

	.match-hdr:hover {
		cursor: pointer;
	}

	.match-sched-away {
		min-width: 316px;
		display: inline-flex;
	}

	.away-label {
		display: inline-flex;
		min-width: 60px;
	}
	
	.result-row-label {
		display: inline-flex;
	}

	.result-row-value {
		margin-right: 14px;
	}

	.row-1 {
		margin-left: 68px;
		margin-top: 6px
	}

	.away-value {
		display: inline-flex;
	}

	.match-info { 
		margin-left: 10px;
	}

	.pool-wk-link {
		display: inline-flex;
		margin-left: 50px;
	}
</style>

