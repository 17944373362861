<script>
	import Fa from 'svelte-fa'
	import { faSave, faXmark } from '@fortawesome/free-solid-svg-icons'
    import { UpdateStandings2 } from '../shared/utils'

	export let showScore

	let standingsText = ''

	async function saveMLBClick() {
		let textData = JSON.parse(standingsText)

		let AL = textData.MLB_Standings.American_League
		let NL = textData.MLB_Standings.National_League

		let teams = AL.East
		teams.push(...AL.Central)
		teams.push(...AL.West)
		teams.push(...NL.East)
		teams.push(...NL.Central)
		teams.push(...NL.West)

		await UpdateStandings2(teams, 'MLB')

		showScore = false;
	}

	async function saveNFLClick() {
		let textData = JSON.parse(standingsText)

		let AFC = textData.NFL_Standings.AFC
		let NFC = textData.NFL_Standings.NFC

		let teams = AFC.East
		teams.push(...AFC.North)
		teams.push(...AFC.South)
		teams.push(...AFC.West)
		teams.push(...NFC.East)
		teams.push(...NFC.North)
		teams.push(...NFC.South)
		teams.push(...NFC.West)

		await UpdateStandings2(teams, 'NFL')

		showScore = false;
	}
</script>

<div class="col-12 page-wrapper">
	<div class="col-11 page">
		<div class="page-x">
			<button class="btn btn-dark" on:click={() => showScore = !showScore }>
				<Fa icon={faXmark}/>
			</button>
		</div>
		<div class="col-12 d-flex" style="text-align: left; margin-top: 50px;">
			<div style="width: 50%">
				<div><span>2024 MLB Standings in a custom JSON format using short team names</span></div>
				<div><span>2024 NFL Standings in a custom JSON format using short team names</span></div>
				<textarea rows="21" style="width: 90%" bind:value={ standingsText } />
				<div style="width='100%; float: right">
					<button class="btn btn-primary" title="Save" tab-index="3" on:click={ saveMLBClick }>
						<Fa icon={ faSave }/>
						<span style="padding-left: 10px;">MLB Save</span>
					</button>
					<button class="btn btn-primary" title="Save" tab-index="3" on:click={ saveNFLClick }>
						<Fa icon={ faSave }/>
						<span style="padding-left: 10px;">NFL Save</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<style>
	.page-wrapper {
		display: flex;
		justify-content: center;
	}

	.page {
		border: 1px solid var(--bs-primary);
		border-radius: 6px;
		background-color: lightgray;
		color: var(--bs-primary);
		padding: 10px;
		font-size: 20px;
	}

	.page-x {
		position: absolute;
		top: 94px;
		left: 92%
	}
</style>