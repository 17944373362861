﻿import { user } from './store';
import { get } from 'svelte/store';
import Swal from 'sweetalert2';

export const GetSiteSettings = async () => {
    const accessToken = get(user).access_token;
    const response = await fetch(`/api/getsitesettings`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleResponse(response);
};

export const GetUsers = async () => {
    const accessToken = get(user).access_token;
    const response = await fetch(`/api/getusers/`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleResponse(response);
}

export const SaveUserBalance = async (saveData) => {

    const accessToken = get(user).access_token;
    const response = await fetch(`/api/saveuserbalance`, {
        method: 'POST',
        body: JSON.stringify(saveData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return teamData;
}; 

export const MLBStandings = async () => {
    const accessToken = get(user).access_token;
    const response = await fetch(`/api/getstandings/mlb/`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleResponse(response);
};

export const Pick5GetTeams = async (league, season = "") => {
    const accessToken = get(user).access_token;
    const response = await fetch('/api/getpick5teamlist/' + league + '/' + season, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleResponse(response);
};

export const NFLStandings = async () => {
    const accessToken = get(user).access_token;
    const response = await fetch(`/api/getstandings/nfl`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleResponse(response);
};

export const NFLPick5GetTeams = async (season = "") => {
    const accessToken = get(user).access_token;
    const response = await fetch(`/api/getpick5teamlist/nfl/` + season, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleResponse(response);
};

export const UpdateStandings = async (league, team, type, val) => {
    var teamData = {};
    teamData.League = league;
    teamData.Team = team;
    teamData.Type = type;
    teamData.Value = val;

    const accessToken = get(user).access_token;
    const response = await fetch(`/api/updatestandings`, {
        method: 'POST',
        body: JSON.stringify(teamData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return teamData;
}; 

export const UpdateStandings2 = async (teams, league) => {
    var teamData = {};
    teamData.Teams = teams;
    teamData.League = league;

    const accessToken = get(user).access_token;
    const response = await fetch(`/api/updatestandings2`, {
        method: 'POST',
        body: JSON.stringify(teamData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return teamData;
}; 

export const Update1333 = async (league, team, week, winnings) => {
    var updateData = {};
    updateData.League = league;
    updateData.Team = team;
    updateData.Week = week;
    updateData.Winnings = winnings;

    const accessToken = get(user).access_token;
    const response = await fetch('/api/update1333', {
        method: 'POST',
        body: JSON.stringify(updateData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return updateData;
}; 

export const AddWin = async (team) => {
    var teamData = {};
    teamData = team;

    const accessToken = get(user).access_token;
    const response = await fetch(`/api/addwin`, {
        method: 'POST',
        body: JSON.stringify(teamData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleResponse(response);
}; 

export const AddLoss = async (team) => {
    var teamData = {};
    teamData = team;

    const accessToken = get(user).access_token;
    const response = await fetch(`/api/addloss`, {
        method: 'POST',
        body: JSON.stringify(teamData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleResponse(response);
}; 

export const GetUserPick5Entries = async (league) => {
    const accessToken = get(user).access_token;
    const response = await fetch(`/api/getuserpicks/` + league, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleResponse(response);
}

export const SavePick5 = async (league, entrynum, picks) => {
    var pickInfo = {};
    pickInfo.League = league;
    pickInfo.EntryNum = entrynum;
    pickInfo.Picks = picks;

    const accessToken = get(user).access_token;
    const response = await fetch(`/api/pick5save`, {
        method: 'POST',
        body: JSON.stringify(pickInfo),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return pickInfo;
}; 

export const GetPick5Results = async (league) => {
    const accessToken = get(user).access_token;
    const response = await fetch(`/api/getpick5results/` + league, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleResponse(response);
}

export const RunGameSelectTeam = async (league, season = "") => {
    const accessToken = get(user).access_token;
    const response = await fetch(`/api/rungameselectteasm/` + league + `/` + season, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleResponse(response);
}

export const RunGameGetMyTeams = async (league, season = "") => {
    const accessToken = get(user).access_token;
    const response = await fetch(`/api/rungamegetmyteams/` + league + `/` + season, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleResponse(response);
}

export const RunGameGetAllTeams = async (league, season = "") => {
    const accessToken = get(user).access_token;
    const response = await fetch(`/api/rungamegetallteams/` + league + `/` + season, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleResponse(response);
}

export const OverUnderGetTeams = async (league, season = "") => {
    const accessToken = get(user).access_token;
    const response = await fetch(`/api/getouteamlist/` + league + `/` + season, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleResponse(response);
}

export const GetUserOUEntries = async (league) => {
    const accessToken = get(user).access_token;
    const response = await fetch(`/api/getuseroupicks/` + league, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleResponse(response);
}

export const SaveOU = async (league, entrynum, picks) => {
    var pickInfo = {};
    pickInfo.League = league;
    pickInfo.EntryNum = entrynum;
    pickInfo.Picks = picks;

    const accessToken = get(user).access_token;
    const response = await fetch(`/api/ousave/`, {
        method: 'POST',
        body: JSON.stringify(pickInfo),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return pickInfo;
}; 

export const GetOUResults = async (league) => {
    const accessToken = get(user).access_token;
    const response = await fetch(`/api/getouresults/` + league, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleResponse(response);
}

export const Authenticate = async (userName, password) => {
    var params = { UserName: userName, Password: password };
    const response = await fetch(`/api/authenticate`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(params)
    });
    var result = await response;
    if (response.ok) {
        var res = await result.json();
        SetUser(res);
        return res      
    } else {
        return null;
    }
};

export const CheckUserName = async (username) => {
    var data = {};
    data.username = username;

    const response = await fetch(`/api/checkusername/`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify(data)
    });
    return await handleResponse(response);
}

export const RegisterUser = async (userInfo) => {

    const response = await fetch(`/api/registeruser/`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
        },
        body: JSON.stringify(userInfo)
    });
    return await handleResponse(response);
}

export const LogIn = async (accessToken) => {
    /*const accessToken = accessToken;*/
    const response = await fetch(`/api/clockin`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleResponse(response);
};

export const LogOut = async (userID) => {
    var params = { UserID: userID };

    const accessToken = get(user).access_token;
    const response = await fetch(`/api/clockout`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(params)
    });
    return await handleResponse(response);
};

export const SetUser = function(result) {
    user.set({
        access_token: result.access_token,
        userID: result.userID,
        firstName: result.firstName,
        lastName: result.lastName,
        userName: result.userName,
        email: result.email,
        isAdmin: result.isAdmin,
        isPool: result.isPool,
        balance: result.balance
    });
}

export const CreateKVP = function (list, keyField, valueField) {
    var kvpList = [];
    var kvp = {}

    var isArrayList = Array.isArray(list);

    for (var i = 0; i < list.length; i++) {
        kvp = {};

        if (isArrayList) {
            kvp.Key = list[i][keyField];
            kvp.Value = list[i][valueField];
        }
        else {
            kvp.Key = list[i];
            kvp.Value = list[i];
        }
        kvpList.push(kvp);
    }

    return kvpList;
}

export const CreateColList = function(row) {
    var colList = [];

    for (var key in row) {
        colList.push(key);
    }

    return colList;
}

async function handleResponse(response) {
    var jResponse = await response.json();
    if (response.error) {
        Swal.fire({
            text: response.error,
            icon: 'error',
            color: '#e1e1e1',
            background: '#444444',
            confirmButtonColor: '#375A7F'
        });
        return null;
    } else {
        return jResponse.result;
    }
}

