﻿<script>
	
	import { onMount } from 'svelte';
    import { Label } from 'sveltestrap';
	import { NFLStandings } from '../shared/utils';
	import EugGrid from './shared/EugGrid.svelte';
	import { UpdatedTeam, user } from '../shared/store';

	let showActions = false;

	$: {
		showActions = $user.isAdmin;
		addActions();
	}

	$: {
		if ($UpdatedTeam && Object.keys($UpdatedTeam).length > 0) {
			switch ($UpdatedTeam.League.toString())
			{
				case "AFC East":
					AFCErows = [...UpdateRecord(AFCErows)];
					break;
				case "AFC North":
					AFCNrows = [...UpdateRecord(AFCNrows)];
					break;
				case "AFC South":
					AFCSrows = [...UpdateRecord(AFCSrows)];
					break;
				case "AFC West":
					AFCWrows = [...UpdateRecord(AFCWrows)];
					break;
				case "NFC East":
					NFCErows = [...UpdateRecord(NFCErows)];
					break;
				case "NFC North":
					NFCNrows = [...UpdateRecord(NFCNrows)];
					break;
				case "NFC South":
					NFCSrows = [...UpdateRecord(NFCSrows)];
					break;
				case "NFC West":
					NFCWrows = [...UpdateRecord(NFCWrows)];
					break;
				default:
					break;
			}		

			UpdatedTeam.set({});
		}
	}

	function UpdateRecord(leagueGroup){
		if ($UpdatedTeam.Type == 'AdjWin')	
			leagueGroup.find(row => { return row.Team.toString() === $UpdatedTeam.Team.toString() }).W = $UpdatedTeam.Value;
		else
			leagueGroup.find(row => { return row.Team.toString() === $UpdatedTeam.Team.toString() }).L = $UpdatedTeam.Value;
		return leagueGroup;
	}

	let cols = ['Team','W','L']

	//NFL
	let AFCErows = [];
	let AFCNrows = [];
	let AFCSrows = [];
	let AFCWrows = [];
	let NFCErows = [];
	let NFCNrows = [];
	let NFCSrows = [];
	let NFCWrows = [];

	let gridActions = { "Actions": [] };
	function addActions() {
		gridActions = { "Actions": [] };

		if (showActions) {
			gridActions.Actions.push({ "ColName": "Add W", "Control": "AdjWin", "Type": "Control" });
			gridActions.Actions.push({ "ColName": "Add L", "Control": "AdjLoss", "Type": "Control" });
		}
	}

	onMount(async () => {
		await GetNFLStandings();

		addActions();
	});

	async function GetNFLStandings() {
		var result = await NFLStandings();

		if (result && result.data){
			var rows = result.data;
			if (rows.length > 0) {
				cols = ['Team','W','L']
			}

			AFCErows = rows.filter((row) => row.League.toString() === "AFC East");
			AFCNrows = rows.filter((row) => row.League.toString() === "AFC North");
			AFCSrows = rows.filter((row) => row.League.toString() === "AFC South");
			AFCWrows = rows.filter((row) => row.League.toString() === "AFC West");
			NFCErows = rows.filter((row) => row.League.toString() === "NFC East");
			NFCNrows = rows.filter((row) => row.League.toString() === "NFC North");
			NFCSrows = rows.filter((row) => row.League.toString() === "NFC South");
			NFCWrows = rows.filter((row) => row.League.toString() === "NFC West");
		}
	}
</script>
		<div class="eug-inline">
			<Label style="width: 100%; text-align: center;">AFC</Label>
			<div class="eug-inline eug-talign-left standings-card">
				<span class="grid-card">
					<EugGrid bind:gridRowsList={AFCErows} gridColsList={cols} tableHeader="" gridActionsList={gridActions.Actions}/>
				</span>
				<span class="grid-card">
					<EugGrid bind:gridRowsList={AFCNrows} gridColsList={cols} tableHeader="" gridActionsList={gridActions.Actions}/>
				</span>
				<span class="grid-card">
					<EugGrid bind:gridRowsList={AFCSrows} gridColsList={cols} tableHeader="" gridActionsList={gridActions.Actions}/>
				</span>
				<span class="grid-card">
					<EugGrid bind:gridRowsList={AFCWrows} gridColsList={cols} tableHeader="" gridActionsList={gridActions.Actions}/>
				</span>
			</div>
		</div>
		<div class="eug-inline">
			<Label style="width: 100%; text-align: center;">NFC</Label>
			<div class="eug-inline eug-talign-left standings-card">
				<span class="grid-card">
					<EugGrid bind:gridRowsList={NFCErows} gridColsList={cols} tableHeader="" gridActionsList={gridActions.Actions}/>
				</span>
				<span class="grid-card">
					<EugGrid bind:gridRowsList={NFCNrows} gridColsList={cols} tableHeader="" gridActionsList={gridActions.Actions}/>
				</span>
				<span class="grid-card">
					<EugGrid bind:gridRowsList={NFCSrows} gridColsList={cols} tableHeader="" gridActionsList={gridActions.Actions}/>
				</span>
				<span class="grid-card">
					<EugGrid bind:gridRowsList={NFCWrows} gridColsList={cols} tableHeader="" gridActionsList={gridActions.Actions}/>
				</span>
			</div>
		</div>
<style>
	.standings-card {
		min-width: 390px;
	}

	.grid-card  {
		display: flex;
		margin-bottom: 6px;
	}
</style>