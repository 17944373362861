import { user } from './store';
import { get } from 'svelte/store';

export const GetPoolLeagueData = async (season) => {
    if (!season)
        season = '2025';

    const accessToken = get(user).access_token;
    const response = await fetch(`/api/poolleague/getdata/${season}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleResponse(response);
};

export const SaveMatchData = async (matchData) => {
    const accessToken = get(user).access_token;
    const response = await fetch(`/api/poolleague/savematch`, {
        method: 'POST',
        body: JSON.stringify(matchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return matchData;
}; 

export const SaveSwitchPlayer = async (switchData) => {
    const accessToken = get(user).access_token;
    const response = await fetch(`/api/poolleague/switchplayer`, {
        method: 'POST',
        body: JSON.stringify(switchData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });

    return await handleResponse(response);
};


async function handleResponse(response) {
    var jResponse = await response.json();
    if (response.error) {
        Swal.fire({
            text: response.error,
            icon: 'error',
            color: '#e1e1e1',
            background: '#444444',
            confirmButtonColor: '#375A7F'
        });
        return null;
    } else {
        return jResponse;
    }
}